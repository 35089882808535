import { useState } from 'react'

const useSessionStorage = <T = unknown>(
  keyName: string,
  defaultValue?: T,
): { value: T; setValue: (v: T) => void; removeKey: () => void } => {
  const [storedValue, setStoredValue] = useState(() => {
    if (typeof window === 'undefined') {
      return defaultValue
    }

    try {
      const value = window.sessionStorage.getItem(keyName)
      if (value) {
        return JSON.parse(value)
      } else {
        return defaultValue
      }
    } catch (err) {
      console.error(err)
      return defaultValue
    }
  })

  const setValue = (newValue: T) => {
    try {
      window.sessionStorage.setItem(keyName, JSON.stringify(newValue))
      setStoredValue(newValue)
    } catch (err) {
      console.error(err)
    }
  }

  const removeKey = () => {
    try {
      window.sessionStorage.removeItem(keyName)
      setStoredValue(defaultValue)
    } catch (err) {
      console.error(err)
    }
  }

  return {
    value: storedValue,
    setValue,
    removeKey,
  }
}

export default useSessionStorage
